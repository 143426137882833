import axios from 'axios';
import {getAttributeIdByName, getAsset} from "../../Utils/UtilsGeneric.js";
import moment from 'moment';

async function loadRawSchedules(attributeList) {
    let tallyosTokenTemp = await localStorage.getItem('tallyosToken');
    let tallyosUserTemp = await localStorage.getItem('tallyosUsername');
    let tallyosUserId = await localStorage.getItem('tallyosUserId');
    let tempFiltering = {};
    let tempUserFiltering = {};
    let formData = new FormData();
    tempFiltering[getAttributeIdByName(attributeList, "tallyos_schedules_date")] = moment().format("YYYY-MM-DD") + " 00:00:00"; 
    
    //Create user filtering object
    tempUserFiltering["USER_ID"] = tallyosUserId;
    tempUserFiltering["ATTRIBUTE_ID"] = getAttributeIdByName(attributeList, "tallyos_schedules_employee_id");

    //Create data object
    let dataObject = {};
    dataObject['JSON_ATTRIBUTES_ID'] = tempFiltering;
    dataObject['JSON_USER'] = tempUserFiltering;
    const dataObjectjStr = JSON.stringify(dataObject);

    formData.append('LICENCE', process.env.REACT_APP_LICENCE_LOGISTICS);
    formData.append('LOGIN', tallyosUserTemp);
    formData.append('TOKEN', tallyosTokenTemp);
    formData.append('DATA', dataObjectjStr);

    const headers = {
        'Content-Type': 'text/plain'
    };
        
    
    let apiResponse = await axios.post(
        process.env.REACT_APP_BASE_URL+'/logistics/searchAsset',
        formData,
        {headers}
        )
        .then(responseJSON => {
            if(responseJSON.data.status === true){
                let rawSchedules = responseJSON.data.Values;
                return {success: true, response: rawSchedules};
            }else{
                console.log("Data gathering issue");
            } 
        })
        .catch(error => {
            console.log("Error ========>", error);
            alert("Connection problem");
            return {success: false, response: error};
        }
        
    )
    return apiResponse;
    
}

export function checkExistingSite(rawSites, siteQr){
    let status = false;
    let snTemp = "";
    for (const [key, value] of Object.entries(rawSites)) {
        if(value.sn === siteQr){
            status = true;
            snTemp = key;
        }
    }    
    return {status: status, sn: snTemp};
}
export function combineScheduleListDescription(scheduleDescriptionArray){
    let output = scheduleDescriptionArray[0];
    if(scheduleDescriptionArray.length > 1){
        for(var i=1; i < scheduleDescriptionArray.length; i++) { 
        output += " // " + scheduleDescriptionArray[i];
        }
        
    }
    return output;
}

export function computeSiteCompletion(scheduleCompletionArray){
    let output = 0
        for(var i=0; i < scheduleCompletionArray.length; i++) { 
        output += scheduleCompletionArray[i];
        }
    return Math.round(output/scheduleCompletionArray.length);
}

export async function loadRawSites() {
    let tallyosTokenTemp = await localStorage.getItem('tallyosToken');
    let tallyosUserTemp = await localStorage.getItem('tallyosUsername');
    
    let formData = new FormData();
        
    //Create data object
    let dataObject = {};
    dataObject['ASSET_CATEGORY_ID'] = "49";
    const dataObjectjStr = JSON.stringify(dataObject);

    formData.append('LICENCE', process.env.REACT_APP_LICENCE_LOGISTICS);
    formData.append('LOGIN', tallyosUserTemp);
    formData.append('TOKEN', tallyosTokenTemp);
    formData.append('DATA', dataObjectjStr);

    const headers = {
        'Content-Type': 'text/plain'
    };
        
    
    let apiResponse = await axios.post(
        process.env.REACT_APP_BASE_URL+'/logistics/getAssetFromCategory',
        formData,
        {headers}
        )
        .then(responseJSON => {
            if(responseJSON.data.status === true){
                let rawSites = responseJSON.data.Values;
                //console.log(rawSites)
                return {success: true, response: rawSites};
            }else{
                console.log("Data gathering issue");
            } 
        })
        .catch(error => {
            console.log("Error ========>", error);
            alert("Connection problem");
            return {success: false, response: error};
        }
        
    )
    return apiResponse;
    
}

export async function getFinalSchedules(attributes, rawSitesCall) {
    let finalScheduleList = [];
    let rawSchedulesCall = await loadRawSchedules(attributes);
    if(rawSchedulesCall.success){
        let rawSchedules = rawSchedulesCall.response;
        let rawSites = rawSitesCall.response;
        if(rawSchedules){
            for (const [key, value] of Object.entries(rawSchedules)) {
                let rawScheduleTempAttributes = value.attributes;
                let ScheduleSiteIdTemp = rawScheduleTempAttributes[getAttributeIdByName(attributes, "tallyos_schedules_worksites")].value;
                let scheduleSiteTemp = rawSites[ScheduleSiteIdTemp].name;
                let scheduleDescriptionTemp = rawScheduleTempAttributes[getAttributeIdByName(attributes, "tallyos_schedules_details")].value;
                let completionTemp = await computeScheduleCompletion(key, attributes);

                let siteIndex = finalScheduleList.findIndex(x => x.schedule_site_id === ScheduleSiteIdTemp); //Check if a schedule already exists for this site
                if(siteIndex === -1){
                    finalScheduleList.push({schedule_id: [key], schedule_site_id: ScheduleSiteIdTemp, schedule_status: getScheduleColorFromCompletion(completionTemp), schedule_site: scheduleSiteTemp, schedule_description: [scheduleDescriptionTemp], schedule_completion: [completionTemp] })
                }else{
                    let existingData = finalScheduleList[siteIndex];

                    //Update associated schedule list
                    let scheduleListUpdated = existingData["schedule_id"];
                    scheduleListUpdated.push(key);
                    finalScheduleList[siteIndex]["schedule_id"] = scheduleListUpdated;

                    //Update associated schedule description
                    let scheduleDescriptionUpdated = existingData["schedule_description"];
                    scheduleDescriptionUpdated.push(scheduleDescriptionTemp);
                    finalScheduleList[siteIndex]["schedule_description"] = scheduleDescriptionUpdated;

                    //Update associated site completion
                    let scheduleCompletionUpdated = existingData["schedule_completion"];
                    scheduleCompletionUpdated.push(completionTemp);
                    finalScheduleList[siteIndex]["schedule_completion"] = scheduleCompletionUpdated;
                }
            }
            //console.log(finalScheduleList)
        }
        
    }
    return finalScheduleList;
}

async function computeScheduleCompletion(scheduleId, attributeList){
    let output = 0;
    let totalScheduleTask = 0;
    let scheduleTemp = await getAsset('ASSET_ID', scheduleId, true, false); 
    if(scheduleTemp.success){
        let scheduleChildrenTemp = scheduleTemp.response.children;
        if(scheduleChildrenTemp && Object.keys(scheduleChildrenTemp).length > 0){
            for (const [key, value] of Object.entries(scheduleChildrenTemp)) {
                let taskTemp = await getAsset('ASSET_ID', key, false, false); 
                if(taskTemp.success && taskTemp.response.attributes[getAttributeIdByName(attributeList, "tallyos_schedules_tasks_status")]){
                    output += Number(taskTemp.response.attributes[getAttributeIdByName(attributeList, "tallyos_schedules_tasks_status")].value);
                    totalScheduleTask ++
                }
            }
            output = Math.round(100 * output/totalScheduleTask);
        }else{
            output = 100;
        }
    }
    return output;
}

export function getScheduleColorFromCompletion(completion){
    let color = "text-danger";

    if (completion == 100){
        color = "text-success";
    } else if (completion > 0){
        color = "text-warning";
    }

    return color;
}

export function getSchedulesFromSite(schedules, siteId){
    let existing = false;
    let snTemp = [];
    for (const [key, value] of Object.entries(schedules)) {
        if(value.schedule_site_id === siteId){
            existing = true;
            snTemp.push(value.schedule_id);
        }
    }
    return {existing: existing, id: snTemp};
}

export async function uploadPicture(pictureData, attributes, parentId, categoryId) {
    let tallyosTokenTemp = await localStorage.getItem('tallyosToken');
    let tallyosUserTemp = await localStorage.getItem('tallyosUsername');
    

    let formData = new FormData();
        
    //Create data object
    let dataObject = {};

    let attributeObject = {};
    //attributeObject[getAttributeIdByName(attributes, "tallyos_tasks_image_schedules_task_id")] = "1";
    attributeObject[getAttributeIdByName(attributes, "tallyos_tasks_image_value")] = pictureData;
    dataObject['TYPE'] = "ADD";
    dataObject['ASSET_SN'] = tallyosUserTemp + Date.now();
    dataObject['ASSET_ATTRIBUTES'] = attributeObject;
    dataObject['ASSET_CATEGORY'] = categoryId;
    dataObject['ASSET_NAME'] = "Photo";
    dataObject['FORCE_PARAMETERS'] = "0";
    dataObject['ASSET_PARENTS_IDS'] = parentId;
    dataObject['PARENT'] = [];
    dataObject['USER_ATTRIBUTES'] = {};
    const dataObjectjStr = JSON.stringify(dataObject);
    formData.append('LICENCE', process.env.REACT_APP_LICENCE_LOGISTICS);
    formData.append('LOGIN', tallyosUserTemp);
    formData.append('TOKEN', tallyosTokenTemp);
    formData.append('DATA', dataObjectjStr);

    const headers = {
        'Content-Type': 'text/plain'
    };
        
    
    let apiResponse = await axios.post(
        process.env.REACT_APP_BASE_URL+'/logistics/upsertAsset',
        formData,
        {headers}
        )
        .then(responseJSON => {
            if(responseJSON.data.status === true){
                return {success: true, response: 'Uploaded'};
            }else{
                console.log("Data gathering issue");
            } 
        })
        .catch(error => {
            console.log("Error ========>", error);
            alert("Connection problem");
            return {success: false, response: error};
        }
        
    )

    return apiResponse;
}