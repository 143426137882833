import React from "react";
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate} from "react-router-dom";
import './NavbarGeneric.css';
import { FaAngleLeft, FaCog, FaHome } from "react-icons/fa";
import { Button } from "react-bootstrap";
export default function NavbarGeneric({navTitle, leftIcon, rightIcon}){
    let navigate = useNavigate();
    let rightIconLogo = <FaHome size={25} />;
    let rightIconLink = "/";
    if(rightIcon === "settings"){
        rightIconLogo = <FaCog size={25} />;
        rightIconLink = "/settings";
    }
    
    return <Navbar>
                <Nav className="mr-auto navText" color="white" >
                    {
                        (leftIcon.url !== "") ?
                            <Button style={{backgroundColor:"transparent", border: "none"}} onClick={() => navigate(leftIcon.url, {state:leftIcon.params})}>{<FaAngleLeft size={25} />}</Button>
                            : null
                    }
                </Nav>
                <Nav className="ms-auto navText">
                    {navTitle}
                </Nav>
                <Nav className="ms-auto navText NavbarButton" >
                    <Button style={{backgroundColor:"transparent", border: "none"}} onClick={() => navigate(rightIconLink)}>{rightIconLogo}</Button>
                </Nav>
            </Navbar>
}