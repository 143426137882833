import React, { useEffect, useState } from 'react';
import './App.css';
import Login from './components/Basic/Login.js';
import Redirect from './components/Basic/Redirect.js';
import useIsConnected from './components/Utils/useIsConnected.js';
import useWorkflow from './components/Utils/useWorkflow.js';
import { isUserValid } from './components/Basic/utilsLogin';
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/i18nextConf.js'

function App() {
  const [isLoading, setLoading] = useState(true);
  const { setIsConnected, isConnected  } = useIsConnected(false);
  const { setWorkflow, workflow  } = useWorkflow(false);
  useEffect(() =>{
    const checkForUserValidity = async() =>{
      console.log(isConnected)
      let userValidity = await isUserValid();
      let tallyosConfiguration = await localStorage.getItem('workflow');
      setWorkflow(tallyosConfiguration);
      setIsConnected(userValidity);      
      setLoading(false);
    }
    checkForUserValidity();
  },[])

  if (isLoading) {
    return  <div>
              <div className="loader"></div>
            </div>;
  }else{
    if(isConnected != "connected" ){
      return <Login setIsConnected={setIsConnected} setWorkflow={setWorkflow}/>
    }else{
      return <Redirect setIsConnected={setIsConnected} workflow={workflow}/>
    }
  }
  
}

export default App;
