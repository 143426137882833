import { useState } from 'react';

export default function useWorkflow() {
  
  const userWorkflow =  "";
  const [workflow, setWorkflow] = useState(userWorkflow);

  const saveWorkflow = (userWorkflow) => {
    localStorage.setItem('workflow', userWorkflow);
    setWorkflow(userWorkflow);
  };
  return {
    setWorkflow: saveWorkflow,
    workflow: workflow
  }
}