import React, {useState, useEffect, useCallback} from "react";
import NavbarGeneric from "../../Basic/NavbarGeneric.js";
import './Qc.css';
import { useNavigate, useLocation} from "react-router-dom";
import { renewToken } from "../../Utils/utilsConnectivity.js";
import {ListGroupSchedulesCompletion} from "../../Widgets/ListGroupCustomized.js";
import {AlertBanner} from '../../Widgets/Alerts';

export default function QcMultiSchedule(){
    const location = useLocation();
    location.state.fromUrl = "/logistics/Qc/QcMultiSchedule";
    const [alertState, setAlertState] = useState({title: "", text: "", status: false});
    const [isLoading, setLoading] = useState(true);
    const [dailySchedules, setDailySchedules] = useState([]);
    const [isEditable, setEditable] = useState(false);
    let navigate = useNavigate();

    function computeDailySchedules(fullList){
        let output = [];
        for(var i = 0; i < fullList["schedule_id"].length; i++){
            output.push({schedule_completion: [fullList["schedule_completion"][i]], schedule_description: [fullList["schedule_description"][i]], schedule_id: [fullList["schedule_id"][i]], schedule_site: fullList["schedule_site"]})
        }
        return output;
    }

    useEffect(() => {
        const triggerTokenRenewal = async() =>{
            setLoading(true);
            await renewToken();
            setEditable(location.state.editable);
            setDailySchedules(computeDailySchedules(location.state.fullList));
            setLoading(false);
        }
        triggerTokenRenewal();
    }, [])
    return (
        <div>
            <NavbarGeneric navTitle={"Prestations"} leftIcon={{url: "/logistics/Qc/QcSchedules", params:{}}} rightIcon={"home"}></NavbarGeneric>
            {(isLoading) ?
                <div className="loader"></div> :
                <div>
                    <div className="Body">
                        <AlertBanner setStatus={setAlertState} content={alertState}/>
                        <div className="MenuButtonContainer">
                            Sélectionner une prestation
                        </div>
                        <div className="MenuButtonContainer">
                            <ListGroupSchedulesCompletion listToDisplay={dailySchedules}></ListGroupSchedulesCompletion>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}