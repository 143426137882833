import React, {useState, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import NavbarGeneric from "../../Basic/NavbarGeneric.js";
import './Qc.css';
import { useNavigate, useLocation} from "react-router-dom";
import { renewToken } from "../../Utils/utilsConnectivity.js";
import { FaCamera, FaSync} from "react-icons/fa";
import Camera from 'react-html5-camera-photo';
import {ListGroupTasksCompletion} from "../../Widgets/ListGroupCustomized.js";
import {uploadPicture} from "./UtilsQc.js";
import {getAttributeDetails, getAttributeIdByName, getAsset, upsertAsset, deleteAsset, getCategoryIdFromName} from "../../Utils/UtilsGeneric.js";
import Form from 'react-bootstrap/Form';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { MdZoomIn } from "react-icons/md";
export default function QcTasks(){
    const location = useLocation();
    const [isLoading, setLoading] = useState(true);
    const [alertState, setAlertState] = useState({title: "", text: "", status: false});
    const [taskComment, setTaskComment] = useState("");
    const [isEditable, setEditable] = useState(true);
    const [attributes, setAttributes] = useState(null);
    const [pictures, setPictures] = useState([]);
    const [isPicturing, setPicturing] = useState(false);
    const [pictureCategoryId, setPictureCategoryId] = useState(null)

    const [cameraMode, setCameraMode] = useState("environment")
    const [idealCameraMode, setIdealCameraMode] = useState("environment")

    // Array to keep an history of event detected on the Gallery section and Gallery edition user's rights
    let listEvents = [];
    let editGallery = true;

    let navigate = useNavigate();

    async function removeAsset(assetId){
        setLoading(true);
        let deleteResponse = await deleteAsset(assetId, "SIMPLE");
        await loadPictures(attributes); //TODO: error or success
        setLoading(false);

    }
    async function validateTaskDetail(){
        let dataObjectAttributes = {};
        dataObjectAttributes[getAttributeIdByName(attributes, "tallyos_schedules_tasks_comment")] = taskComment;
        let updateTask = await upsertAsset("EDIT", location.state.taskId, dataObjectAttributes)
        if(updateTask.success){
            navigate(-1);
        }else{
            console.log("Issue while saving")
        }
    }

    // Open or Close the camera
    const getPicture = () => {
        if (isPicturing)
            setPicturing(false)
        else
            setPicturing(true);
    }

    // Switch Camera mode in regard which one is active
    const switchCameraMode = () => {
        let newMode = (cameraMode === "environment") ? "user" : "environment";
        setCameraMode(newMode);
        setIdealCameraMode(newMode);
    }

    async function handleTakePhoto (dataUri) {
        setLoading(true);
        await uploadPicture(dataUri, attributes, [location.state.taskId], pictureCategoryId);
        setPicturing(false);
        
        await loadPictures(attributes);
        setLoading(false);
    }

    const handleClick = (event) => {
        event.preventDefault();

        let newEvent = {detail : event.detail, target : event.target}
        listEvents = listEvents.concat(newEvent); // Add the event in the list

        setTimeout(() => {
            if (listEvents.length > 0) { // Check if the list contains something
                let eventToProcess = listEvents.at(-1); // Retrieve the latest element of the list
                switch(eventToProcess.detail) {
                    case 1: // One click, we open the photo
                        eventToProcess.target.requestFullscreen();
                        break;
                    case 2: // Two clicks, Modal to delete the picture
                        if (editGallery) {
                            confirmAlert({
                                title: 'Suppression',
                                message: 'Etes-vous sûr de vouloir supprimer cette photo ?',
                                buttons: [
                                    {
                                        label: 'Oui',
                                        onClick: () => {removeAsset(eventToProcess.target.id)}
                                    },
                                    {
                                        label: 'Non',
                                        onClick: () => null
                                    }
                                ]
                            });
                        }
                        break;

                    default:
                    // Do nothing
                }

                listEvents = []; // Empty the list of events
            }
        }, 1000);
    }

    async function displayPictures(pictures){
        let picturesTemp = [];
        for (const [key, value] of Object.entries(pictures)) {
            let pictureTemp = await getAsset('ASSET_ID', key, false, false) 
            if(pictureTemp.success && pictureTemp.response){
                let picTemp = pictureTemp.response;
                picturesTemp.push(
                    <img src={picTemp.attributes['105'].value} className="PictureMiniature" alt="" id={key} key={key} onClick={handleClick}></img>
                )
            }
        }
        return picturesTemp;
        
    }

    async function loadPictures(attributeTemp){
        let taskDetails = await getAsset('ASSET_ID', location.state.taskId, true, false);
        setTaskComment(taskDetails.response.attributes[getAttributeIdByName(attributeTemp, "tallyos_schedules_tasks_comment")].value);
        let picturesToDisplay = await displayPictures(taskDetails.response.children);
        setPictures(picturesToDisplay);
    }

    useEffect(() => {
        const triggerTokenRenewal = async() =>{
            setLoading(true);
            await renewToken();
            setEditable(location.state.editable);
            editGallery = location.state.editable; // Save the status in a classic variable to avoid latency between status refresh
            let attributeTemp = await getAttributeDetails();
            setAttributes(attributeTemp.response);

            await loadPictures(attributeTemp.response);
            let pictureCategoryId = await getCategoryIdFromName("tallyos_tasks_image");
            if(pictureCategoryId.success){
                setPictureCategoryId(pictureCategoryId.response);
            }
            
            setLoading(false);
        }
        triggerTokenRenewal();
    }, [])
    return (
        <div>
            <NavbarGeneric navTitle={"Tâche"} leftIcon={{url: "/logistics/Qc/QcTasks", params:{scheduleId: location.state.scheduleId, editable: isEditable, fromUrl: location.state.fromUrl, fullList: location.state.fullList}}} rightIcon={"home"}></NavbarGeneric>
            {
              (isLoading) ? <div className="loader"></div>
                :<div>
                    <div className="Body">
                        {(isPicturing)?
                            <div className="row">
                                <div className="col-12">
                                    <Camera
                                        facingMode = {cameraMode}
                                        idealFacingMode = {idealCameraMode}
                                        onTakePhoto = { async (dataUri) => { await handleTakePhoto(dataUri); } }
                                        imageCompression = {0.1}
                                        idealResolution = {{width: 640, height: 480}}
                                        isFullscreen={false}
                                    />
                                </div>
                            </div>
                            :null
                        }
                        
                        <Form>
                            <Form.Group className="mb-3" controlId="Pictures">
                                <div className="d-flex justify-content-around align-items-center">
                                    {(isEditable && pictures.length < 2)?
                                        <div>
                                            <Button className="btn-primary align-items-center"
                                                    style={{height:'2.5em', borderRadius:'1.5em', marginRight:'1em'}}
                                                    onClick={() => switchCameraMode()}>
                                                <FaSync style={{marginBottom:'0.15em'}} />
                                            </Button>
                                            <Button className="btn-primary align-items-center"
                                                    style={{height:'2.5em', borderRadius:'1.5em'}}
                                                    onClick={() => getPicture()}>
                                                <FaCamera style={{marginBottom:'0.15em'}} />
                                            </Button>
                                        </div>
                                        :null}
                                </div>

                                <Form.Label>Photos : </Form.Label>

                                <div className="d-flex justify-content-around align-items-center mt-3 mb-3">
                                    {pictures}
                                </div>

                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formComments">
                                <Form.Label>Commentaires :</Form.Label>
                                <Form.Control as="textarea" rows="3" disabled={!isEditable} placeholder="Commentaires" value={taskComment ? taskComment:""} onChange={e => setTaskComment(e.target.value)} />
                                <Form.Text className="text-muted">
                                </Form.Text>
                            </Form.Group>

                        </Form>
                        {(isEditable)?
                            <div className="MenuButtonContainer">
                                <Button className="MenuButton" onClick={() => validateTaskDetail()}>
                                    Valider
                                </Button>
                            </div>
                            :null}
                    </div>
            </div>
            }
        </div>
    );
}