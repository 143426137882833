import axios from 'axios';

//Function that sorts array with respect to specific property
export function dynamicSort (property){
    var sortOrder = 1;
    if(property[0] === "-") {
        sortOrder = -1;
        property = property.substr(1);
    }
    return function (a,b) {
        /* next line works with strings and numbers, 
         * and you may want to customize it to your needs
         */
        var result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
        return result * sortOrder;
    }
}

export async function getAttributeDetails(){
    let tallyosTokenTemp = await localStorage.getItem('tallyosToken');
    let tallyosUserTemp = await localStorage.getItem('tallyosUsername');
    
    let formData = new FormData();
    formData.append('LICENCE', process.env.REACT_APP_LICENCE_LOGISTICS);
    formData.append('LOGIN', tallyosUserTemp);
    formData.append('TOKEN', tallyosTokenTemp);

    const headers = {
        'Content-Type': 'text/plain'
    };
        
    
    let apiResponse = await axios.post(
        process.env.REACT_APP_BASE_URL+'/logistics/getAllAttributes',
        formData,
        {headers}
        )
        .then(responseJSON => {
            if(responseJSON.data.status === true || responseJSON.data.status === 200 ){ // TODO: status must be true
                let rawData = responseJSON.data.Values;
                return {success: true, response: rawData};
            }else{
                console.log("Data gathering issue");
            } 
        })
        .catch(error => {
            console.log("Error ========>", error);
            alert("Connection problem");
            return {success: false, response: error};
        }
        
    )
    return apiResponse;
}

export function getAttributeIdByName(object, value) {
    return Object.keys(object).find(key => object[key].name === value);
}

export async function getAsset(keyType, assetKey, children, parents){
    let tallyosTokenTemp = await localStorage.getItem('tallyosToken');
    let tallyosUserTemp = await localStorage.getItem('tallyosUsername');
    
    let formData = new FormData();
    //Create data object
    let dataObject = {};
    dataObject[keyType] = assetKey;
    dataObject['CHILDREN'] = children;
    dataObject['PARENTS'] = parents;
    const dataObjectjStr = JSON.stringify(dataObject);

    formData.append('LICENCE', process.env.REACT_APP_LICENCE_LOGISTICS);
    formData.append('LOGIN', tallyosUserTemp);
    formData.append('TOKEN', tallyosTokenTemp);
    formData.append('DATA', dataObjectjStr);
    const headers = {
        'Content-Type': 'text/plain'
    };
        
    let apiResponse = await axios.post(
        process.env.REACT_APP_BASE_URL+'/logistics/getAsset',
        formData,
        {headers}
        )
        .then(responseJSON => {
            if(responseJSON.data.status === true || responseJSON.data.status === 200 ){
                let rawData = responseJSON.data.Values;
                return {success: true, response: rawData};
            }else{
                console.log("Data gathering issue");
            } 
        })
        .catch(error => {
            console.log("Error ========>", error);
            alert("Connection problem");
            return {success: false, response: error};
        }
        
    )
    return apiResponse;
}

export async function deleteAsset(assetId, type){
    let tallyosTokenTemp = await localStorage.getItem('tallyosToken');
    let tallyosUserTemp = await localStorage.getItem('tallyosUsername');
    
    let formData = new FormData();
    //Create data object
    let dataObject = {};
    dataObject['ASSET_ID'] = assetId;
    dataObject['TYPE'] = type;
    const dataObjectjStr = JSON.stringify(dataObject);

    formData.append('LICENCE', process.env.REACT_APP_LICENCE_LOGISTICS);
    formData.append('LOGIN', tallyosUserTemp);
    formData.append('TOKEN', tallyosTokenTemp);
    formData.append('DATA', dataObjectjStr);
    const headers = {
        'Content-Type': 'text/plain'
    };
    console.log(formData, assetId)
    let apiResponse = await axios.post(
        process.env.REACT_APP_BASE_URL+'/logistics/deleteAsset',
        formData,
        {headers}
        )
        .then(responseJSON => {
            if(responseJSON.data.status === true){
                let rawData = responseJSON.data.Values;
                return {success: true, response: rawData};
            }else{
                console.log("Data gathering issue");
            } 
        })
        .catch(error => {
            console.log("Error ========>", error);
            alert("Connection problem");
            return {success: false, response: error};
        }
        
    )
    return apiResponse;
}


export async function getAssetFromCategory(categoryId){
    let tallyosTokenTemp = await localStorage.getItem('tallyosToken');
    let tallyosUserTemp = await localStorage.getItem('tallyosUsername');
    
    let formData = new FormData();
    //Create data object
    let dataObject = {};
    dataObject['ASSET_CATEGORY_ID'] = categoryId;
    const dataObjectjStr = JSON.stringify(dataObject);

    formData.append('LICENCE', process.env.REACT_APP_LICENCE_LOGISTICS);
    formData.append('LOGIN', tallyosUserTemp);
    formData.append('TOKEN', tallyosTokenTemp);
    formData.append('DATA', dataObjectjStr);
    const headers = {
        'Content-Type': 'text/plain'
    };
        
    let apiResponse = await axios.post(
        process.env.REACT_APP_BASE_URL+'/logistics/getAssetFromCategory',
        formData,
        {headers}
        )
        .then(responseJSON => {
            if(responseJSON.data.status === true){
                let rawData = responseJSON.data.Values;
                return {success: true, response: rawData};
            }else{
                console.log("Data gathering issue");
            } 
        })
        .catch(error => {
            console.log("Error ========>", error);
            alert("Connection problem");
            return {success: false, response: error};
        }
        
    )
    return apiResponse;
}


export async function getAllCategories(){
    let tallyosTokenTemp = await localStorage.getItem('tallyosToken');
    let tallyosUserTemp = await localStorage.getItem('tallyosUsername');
    
    let formData = new FormData();

    formData.append('LICENCE', process.env.REACT_APP_LICENCE_LOGISTICS);
    formData.append('LOGIN', tallyosUserTemp);
    formData.append('TOKEN', tallyosTokenTemp);
    const headers = {
        'Content-Type': 'text/plain'
    };
        
    let apiResponse = await axios.post(
        process.env.REACT_APP_BASE_URL+'/logistics/getCategories',
        formData,
        {headers}
        )
        .then(responseJSON => {
            if(responseJSON.data.status === true || responseJSON.data.status === 200 ){ // TODO: status must be true
                let rawData = responseJSON.data.Values;
                return {success: true, response: rawData};
            }else{
                console.log("Data gathering issue");
            } 
        })
        .catch(error => {
            console.log("Error ========>", error);
            alert("Connection problem");
            return {success: false, response: error};
        }
        
    )
    return apiResponse;
}

export async function upsertAsset(upsertType, assetId, dataObjectAttributes){
    let tallyosTokenTemp = await localStorage.getItem('tallyosToken');
    let tallyosUserTemp = await localStorage.getItem('tallyosUsername');
    
    //let dataObjectAttributes = {};
    //dataObjectAttributes['102'] = "0";

    let formData = new FormData();
    //Create data object
    let dataObject = {};
    dataObject['TYPE'] = upsertType;
    dataObject['ASSET_ID'] = assetId;
    dataObject['ASSET_ATTRIBUTES'] = dataObjectAttributes;

    const dataObjectjStr = JSON.stringify(dataObject);

    formData.append('LICENCE', process.env.REACT_APP_LICENCE_LOGISTICS);
    formData.append('LOGIN', tallyosUserTemp);
    formData.append('TOKEN', tallyosTokenTemp);
    formData.append('DATA', dataObjectjStr);
    const headers = {
        'Content-Type': 'text/plain'
    };
        
    let apiResponse = await axios.post(
        process.env.REACT_APP_BASE_URL+'/logistics/upsertAsset',
        formData,
        {headers}
        )
        .then(responseJSON => {
            console.log(responseJSON)
            if(responseJSON.data.status === true){
                let rawData = responseJSON.data.Values;
                return {success: true, response: rawData};
            }else{
                console.log("Data gathering issue");
            } 
        })
        .catch(error => {
            console.log("Error ========>", error);
            alert("Connection problem");
            return {success: false, response: error};
        }
        
    )
    return apiResponse;
}

export async function insertAsset(assetName, assetCategory, dataObjectAttributes, parent, parentIds, userAttributes){
    let tallyosTokenTemp = await localStorage.getItem('tallyosToken');
    let tallyosUserTemp = await localStorage.getItem('tallyosUsername');
    
    let formData = new FormData();
    //Create data object
    let dataObject = {};
    dataObject['TYPE'] = "ADD";
    dataObject['ASSET_NAME'] = assetName;
    dataObject['ASSET_ATTRIBUTES'] = dataObjectAttributes;
    dataObject['ASSET_CATEGORY'] = assetCategory;
    dataObject['PARENT'] = parent;
    dataObject['ASSET_PARENTS_IDS'] = parentIds;
    dataObject['USER_ATTRIBUTES'] = userAttributes;
    const dataObjectjStr = JSON.stringify(dataObject);

    formData.append('LICENCE', process.env.REACT_APP_LICENCE_LOGISTICS);
    formData.append('LOGIN', tallyosUserTemp);
    formData.append('TOKEN', tallyosTokenTemp);
    formData.append('DATA', dataObjectjStr);
    const headers = {
        'Content-Type': 'text/plain'
    };
        
    let apiResponse = await axios.post(
        process.env.REACT_APP_BASE_URL+'/logistics/upsertAsset',
        formData,
        {headers}
        )
        .then(responseJSON => {
            console.log(responseJSON)
            if(responseJSON.data.status === true){
                let rawData = responseJSON.data.Values;
                return {success: true, response: rawData};
            }else{
                console.log("Data gathering issue");
            } 
        })
        .catch(error => {
            console.log("Error ========>", error);
            alert("Connection problem");
            return {success: false, response: error};
        }
        
    )
    return apiResponse;
}

export async function getCategoryIdFromName(name){
    let tallyosTokenTemp = await localStorage.getItem('tallyosToken');
    let tallyosUserTemp = await localStorage.getItem('tallyosUsername');
    
    let formData = new FormData();

    formData.append('LICENCE', process.env.REACT_APP_LICENCE_LOGISTICS);
    formData.append('LOGIN', tallyosUserTemp);
    formData.append('TOKEN', tallyosTokenTemp);
    const headers = {
        'Content-Type': 'text/plain'
    };
        
    let apiResponse = await axios.post(
        process.env.REACT_APP_BASE_URL+'/logistics/getCategories',
        formData,
        {headers}
        )
        .then(responseJSON => {
            if(responseJSON.data.status === true){
                let rawData = responseJSON.data.Values;
                return {success: true, response: Object.keys(rawData).find(key => rawData[key].name === name)};
            }else{
                console.log("Data gathering issue");
            } 
        })
        .catch(error => {
            console.log("Error ========>", error);
            alert("Connection problem");
            return {success: false, response: error};
        }
        
    )
    return apiResponse;
}
