import { useState } from 'react';

export default function useIsConnected() {
  const getToken = () => {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);
    return userToken?.token
  };

  const [isConnected, setIsConnected] = useState(getToken());

  const saveToken = userToken => {
    localStorage.setItem('token', JSON.stringify(userToken));
    setIsConnected(userToken);
  };
  return {
    setIsConnected: saveToken,
    isConnected: isConnected
  }
}