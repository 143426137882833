import React, {useState, useRef, useEffect } from "react";
import Button from 'react-bootstrap/Button';
import NavbarGeneric from "../../Basic/NavbarGeneric.js";
import './Qc.css';
import {AlertBanner} from '../../Widgets/Alerts';
import { useNavigate} from "react-router-dom";
import { renewToken } from "../../Utils/utilsConnectivity.js";
import {ListGroupSchedulesCompletion} from "../../Widgets/ListGroupCustomized.js";
import {getFinalSchedules, loadRawSites, checkExistingSite, getSchedulesFromSite} from "./UtilsQc.js";
import {getAttributeDetails, insertAsset, getAttributeIdByName, getCategoryIdFromName, getAsset} from "../../Utils/UtilsGeneric.js";
import QrReader from 'react-qr-reader';
import { useTorchLight } from '@blackbox-vision/use-torch-light';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import {FaAngleLeft, FaHome, FaLightbulb, FaSync} from "react-icons/fa";
import moment from 'moment';
import {computeSiteCompletion} from "./UtilsQc.js";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";

export default function QcSchedules(){
    let navigate = useNavigate();
    let streamRef = useRef(null);
    const [on, toggle] = useTorchLight(streamRef.current);

    let deviceConfig = {
        video: {
            width: 480,
            height: 640,
            facingMode: "environment",
            deviceId: null,
            torch: true, advanced: [{ torch: true }]
        }
    };
    let cameraOpen = false;

    const [isLoading, setLoading] = useState(true);
    const [dailyCompletion, setDailyCompletion] = useState(true);
    const [dailySchedules, setDailySchedules] = useState([]);
    const [isScanning, setScanning] = useState(false);
    const [rawSites, setRawSites] = useState(null);
    const [alertState, setAlertState] = useState({title: "", text: "", status: false});
    const [attributes, setAttributes] = useState(null);
    const { t } = useTranslation();
    async function computeDailyProgresssion(dailySchedules, attributeList){
        let output = 0;
        if(dailySchedules.length > 0){
            for(var i = 0; i < dailySchedules.length; i++){
                output += computeSiteCompletion(dailySchedules[i].schedule_completion);
            }
            output = Math.round(output/dailySchedules.length);
        }else{
            output = 100;
        }
        setDailyCompletion(output+"%")
    }

    async function createNewSchedule(worksiteId){
        let tallyosUserId = await localStorage.getItem('tallyosUserId');

        let dataObjectAttributes = {};
        dataObjectAttributes[getAttributeIdByName(attributes, "tallyos_schedules_date")] = moment().format("YYYY-MM-DD") + " 00:00:00";
        dataObjectAttributes[getAttributeIdByName(attributes, "tallyos_schedules_worksites")] = worksiteId;
        dataObjectAttributes[getAttributeIdByName(attributes, "tallyos_schedules_details")] = "Prestation non prévue";
        dataObjectAttributes[getAttributeIdByName(attributes, "schedules_origin")] = "application"; //TODO: assign location when creating schedule?

        let userAttributes = {};
        userAttributes["USER_ID"] = tallyosUserId;
        userAttributes["ATTRIBUTE_ID"] = getAttributeIdByName(attributes, "tallyos_schedules_employee_id");

        let assetCategoryQuery = await getCategoryIdFromName("tallyos_schedules");
        
        if(assetCategoryQuery.success){
            let assetCategory = assetCategoryQuery.response;
            let createSchedule = await insertAsset("Prestation non prévue", assetCategory, dataObjectAttributes, [], [], userAttributes);
            if(createSchedule.success){
                let newScheduleId = createSchedule.response.id;

                let dataObjectAttributesTask = {};
                dataObjectAttributesTask[getAttributeIdByName(attributes, "tallyos_schedules_tasks_status")] = "0";
                dataObjectAttributesTask[getAttributeIdByName(attributes, "tallyos_schedules_tasks_comment")] = "";
                dataObjectAttributesTask[getAttributeIdByName(attributes, "tallyos_schedules_tasks_task_id")] = newScheduleId;

                let assetCategoryQueryTask = await getCategoryIdFromName("tallyos_schedules_tasks");
                let defaultTaskId = await getAsset('ASSET_SN', "TALLYOS-DEFAULT-UNEXPECTED-TASK", false, false)

                if(assetCategoryQueryTask.success && defaultTaskId.success){
                    let assetCategoryTask = assetCategoryQueryTask.response;
                    let createTask = await insertAsset("Tâche non prévue", assetCategoryTask, dataObjectAttributesTask, [], [newScheduleId], {});
                    //console.log(createTask)
                    if(createSchedule.success){
                        navigate('/logistics/Qc/QcTasks', {state:{scheduleId: newScheduleId, editable: true}});
                    }else{
                        setAlertState({title: t("basic.error"), text: t("basic.connection_error"), status: true, type: "danger"});
                    }

                }else{
                    setAlertState({title: t("basic.error"), text: t("basic.connection_error"), status: true, type: "danger"});
                }
            }else{
                setAlertState({title: t("basic.error"), text: t("basic.connection_error"), status: true, type: "danger"});
            }
        }else{
            setAlertState({title: t("basic.error"), text: t("basic.connection_error"), status: true, type: "danger"});
        }
        
    }

    const getQrCode = () => {
        setScanning(true);
        cameraOpen = true;
    }

    function filterSchedules(schedules, siteId){
        for(var i = 0; i < schedules.length; i++){
            if(schedules[i]["schedule_site_id"] === siteId){
                return schedules[i];
            }
        }
    }

    const getFakeQrCode = () => {
        let isSiteExisting = checkExistingSite(rawSites, "888");
        if(isSiteExisting.status){
            let scheduleIdTemp = getSchedulesFromSite(dailySchedules, isSiteExisting.sn); //TODO: improve if no site or multiple sites
            
            if(scheduleIdTemp.existing){
                setScanning(false);
                if(scheduleIdTemp.id[0].length === 1){
                    console.log(scheduleIdTemp.id[0][0])
                    navigate('/logistics/Qc/QcTasks', {state:{scheduleId: scheduleIdTemp.id[0][0], editable: true}});
                }else{
                    navigate('/logistics/Qc/QcMultiSchedule', {state:{scheduleId: scheduleIdTemp.id[0], editable: true, fullList: filterSchedules(dailySchedules, isSiteExisting.sn)}});
                }
                
            }else{
                setScanning(false);
                confirmAlert({
                    title: "Prestation non attribuée",
                    message: "Souhaitez-vous créer une nouvelle prestation ?",
                    buttons: [
                        {
                        label: "Non",
                        onClick: () => {setScanning(false);}
                        },
                        {
                        label: "Oui",
                        onClick: () => {createNewSchedule(isSiteExisting.sn)}
                        }
                    ]
                    });
            }                                    
        }else{
            setScanning(false);
            alert("Le QR code que vous venez de scanner ne correspond à aucune prestation.");
            //setAlertState({title: "Erreur", text: "Le QR code que vous venez de scanner ne correspond à aucune prestation.", status: true, type: "danger"});
        }
    }

    // get the available video input devices and choose the one that represents the backside camera
    if (navigator !== undefined && navigator.mediaDevices !== undefined && streamRef.current === null) {
       navigator.mediaDevices.enumerateDevices()
            .then(mediaDeviceInfos => mediaDeviceInfos.filter(mediaDeviceInfo => ((mediaDeviceInfo.kind === 'videoinput') && mediaDeviceInfo.label.includes("back"))))
            .then(mediaDeviceInfos => {
                if ((cameraOpen || isScanning) && deviceConfig.video.deviceId === null) {
                    mediaDeviceInfos.forEach((media) => {
                        if (deviceConfig.video.deviceId === null) {
                            deviceConfig.video.deviceId = media.deviceId;
                            navigator.mediaDevices.getUserMedia(deviceConfig)
                                .then(_gotMedia)
                                .catch(err => {
                                        deviceConfig.video.deviceId = null;
                                    }
                                );
                        }
                    });
                }
            });
    }

    function _gotMedia (mediastream) {
        if (streamRef.current === null)
            streamRef.current = mediastream;
    }

    const closeStream = () => {
        if (streamRef.current !== null) {
            streamRef.current.getTracks().forEach(function(track) {
                track.stop();
            });
        }

        deviceConfig.video.deviceId = null
        streamRef.current = null;
        streamRef = null;
        cameraOpen = false;
    }
    useEffect(() => {
        const triggerTokenRenewal = async() =>{
            setLoading(true);
            await renewToken();
            let attributeTemp = await getAttributeDetails();
            setAttributes(attributeTemp.response);
            let rawSitesCall = await loadRawSites();
            setRawSites(rawSitesCall.response);
            let dailyScheduleTemp = await getFinalSchedules(attributeTemp.response, rawSitesCall);
            setDailySchedules(dailyScheduleTemp);
            await computeDailyProgresssion(dailyScheduleTemp, attributeTemp);
            setLoading(false);
        }
        triggerTokenRenewal();
    }, [])

    const FACING_MODE_USER = "user";
    const FACING_MODE_ENVIRONMENT = "environment";
    const [camera, setCamera] = useState(FACING_MODE_ENVIRONMENT);

    return (
        <div>
            {
              (isLoading) ?
              <div>
                  <NavbarGeneric navTitle={"Prestations"} leftIcon={{url: "/logistics/Qc/QcHome", params:{}}} rightIcon={"home"}></NavbarGeneric>
                  <div className="loader"></div>
              </div>
                : (isScanning)? 
                    <div>
                        <Navbar>
                            <Nav className="mr-auto navText" color="white" >
                                <Button style={{backgroundColor:"transparent", border: "none"}} onClick={() => {setScanning(false); closeStream();}}>{<FaAngleLeft size={25} />}</Button>
                            </Nav>
                            <Nav className="ms-auto navText">
                                Prestations
                            </Nav>
                            <Nav className="ms-auto navText NavbarButton" >
                                <Button style={{backgroundColor:"transparent", border: "none"}} onClick={() => {setScanning(false); closeStream(); navigate("/")}}><FaHome size={25} /></Button>
                            </Nav>
                        </Navbar>
                        <QrReader
                            className="mt-5"
                            constraints={{ facingMode: camera, torch: true, advanced: [{ torch: true }] }}
                            delay={300}
                            onScan={(result) => {
                            if (!!result) {
                                closeStream(); // Close the stream
                                console.log(result);
                                let isSiteExisting = checkExistingSite(rawSites, result);
                                if(isSiteExisting.status){
                                    let scheduleIdTemp = getSchedulesFromSite(dailySchedules, isSiteExisting.sn);

                                    if(scheduleIdTemp.existing){
                                        setScanning(false);
                                        if(scheduleIdTemp.id[0].length === 1){
                                            console.log(scheduleIdTemp.id[0][0])
                                            navigate('/logistics/Qc/QcTasks', {state:{scheduleId: scheduleIdTemp.id[0][0], editable: true}});
                                        }else{
                                            navigate('/logistics/Qc/QcMultiSchedule', {state:{scheduleId: scheduleIdTemp.id[0], editable: true, fullList: filterSchedules(dailySchedules, isSiteExisting.sn)}});
                                        }

                                    }else{
                                        setScanning(false);
                                        confirmAlert({
                                            title: "Prestation non attribuée",
                                            message: "Souhaitez-vous créer une nouvelle prestation ?",
                                            buttons: [
                                                {
                                                    label: "Non",
                                                    onClick: () => {setScanning(false);}
                                                },
                                                {
                                                    label: "Oui",
                                                    onClick: () => {createNewSchedule(isSiteExisting.sn)}
                                                }
                                            ]
                                        });
                                    }
                                }else{
                                    setScanning(false);
                                    alert("Le QR code que vous venez de scanner ne correspond à aucune prestation.");
                                    //setAlertState({title: "Erreur", text: "Le QR code que vous venez de scanner ne correspond à aucune prestation.", status: true, type: "danger"});
                                }
                            }
                            }}
                            style={{ width: '100%' }}
                        />
                        <img/>

                        <div className="d-flex justify-content-around" style={{width:"75%", paddingTop:'1em', margin:"auto"}}>
                            <Button className="FlashButton  align-items-center"
                                    style={{height:'2.5em', borderRadius:'1.5em', marginRight:'1em'}}
                                    onClick={toggle}>
                                <FaLightbulb style={{marginBottom:'0.15em'}} />
                            </Button>

                            <Button className="CancelButton" onClick={() => {
                               // Hide the camera Part
                                setScanning(false);
                                closeStream(); // Close the stream
                            }}>
                                Annuler
                            </Button>
                        </div>
                    </div>
                    :<div>
                          <NavbarGeneric navTitle={"Prestations"} leftIcon={{url: "/logistics/Qc/QcHome", params:{}}} rightIcon={"home"}></NavbarGeneric>
                    <div className="Body">
                        <AlertBanner setStatus={setAlertState} content={alertState}/>
                        <div className="MenuButtonContainer">
                            Progression journalière : {dailyCompletion}
                        </div>
                        <div className="MenuButtonContainer">
                            <Button className="MenuButton" onClick={() => getQrCode()}>
                                Démarrer prestation
                            </Button>
                        </div>
                        <div className="MenuButtonContainer">
                            <ListGroupSchedulesCompletion listToDisplay={dailySchedules}></ListGroupSchedulesCompletion>
                        </div>
                    </div>
                    
                </div>
            }
        </div>
    );
}