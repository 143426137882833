import React, { useState, useEffect } from 'react';
import { FaTimesCircle, FaCheckCircle, FaInfoCircle } from "react-icons/fa";
import Alert from 'react-bootstrap/Alert'

function renderIcon(type) {
    switch (type) {
        case 'danger':
            return <FaTimesCircle style={{marginRight:'0.5em',fontSize:'1em'}}/>;
        case 'success':
            return <FaCheckCircle style={{marginRight:'0.5em',fontSize:'1em'}}/>;
        default:
            return <FaInfoCircle style={{marginRight:'0.5em',fontSize:'1em'}}/>;
    }
}

export function AlertBanner({setStatus, content}) {
    return (
        <Alert className={content.status?'fadeIn':'fadeOut'} variant={content.type}>
            {/*<Alert.Heading>{content.title}</Alert.Heading>*/}
            <p style={{marginBottom:0}}>
                {renderIcon(content.type)} {content.text}
            </p>
        </Alert>
    );
  /*setTimeout(() => {
    setStatus({title: "", text: "", status: false, type: "danger"});
  }, 5000)*/
}

