import React, {useState, useEffect, useCallback} from "react";
import Button from 'react-bootstrap/Button';
import NavbarGeneric from "../../Basic/NavbarGeneric.js";
import './Qc.css';
import { useNavigate, useLocation} from "react-router-dom";
import { renewToken } from "../../Utils/utilsConnectivity.js";
import {ListGroupTasksCompletion} from "../../Widgets/ListGroupCustomized.js";
import {getAttributeDetails, getAttributeIdByName, getAsset, upsertAsset} from "../../Utils/UtilsGeneric.js";
import {AlertBanner} from '../../Widgets/Alerts';

export default function QcTasks(){
    const location = useLocation();
    const [alertState, setAlertState] = useState({title: "", text: "", status: false});
    const [isLoading, setLoading] = useState(true);
    const [scheduleCompletion, setScheduleCompletion] = useState(0);
    const [scheduleTasks, setScheduleTasks] = useState(false);
    const [attributes, setAttributes] = useState(null);
    const [isEditable, setEditable] = useState(false);
    let navigate = useNavigate();
    let fromUrl = "/logistics/Qc/QcSchedules"; // Fallback URL

    if (typeof(location.state.fromUrl) !== 'undefined')
        fromUrl = location.state.fromUrl;
    
    function computeDailyProgresssion(taskDetail){
        let output = 0;
        if(taskDetail.length > 0){
            for(var i = 0; i < taskDetail.length; i++){
                if(document.getElementById("checkbox_"+taskDetail[i].id)){
                    if(document.getElementById("checkbox_"+taskDetail[i].id).checked) output++;
                }else{
                    if(taskDetail[i].status) output++
                }
                
            }
            output = Math.round(100 * output/taskDetail.length);
        }else{
            output = 100;
        }
        //console.log(output)
        setScheduleCompletion(output)
    }
    const updateStat = useCallback(() => {
        computeDailyProgresssion(scheduleTasks)
      }, []);
      

    async function formatTaskData(scheduleDetails, attributesList){
        let scheduleTasks = []; 
        for (const [key, value] of Object.entries(scheduleDetails)) {
            let taskTemp = await getAsset('ASSET_ID', key, false, false); 
            if(taskTemp.success){
                let attributesTemp = taskTemp.response.attributes;
                if(attributesTemp[getAttributeIdByName(attributesList, "tallyos_schedules_tasks_status")]){
                    let attributeStatusTemp = (attributesTemp[getAttributeIdByName(attributesList, "tallyos_schedules_tasks_status")].value === "1");
                    scheduleTasks.push({status: attributeStatusTemp, id: key, name: value.name})
                }
                
            }
        }
        return scheduleTasks;
    }

    async function validateScheduleTasks(){
        let overallTransaction = true;
        for (const [key, value] of Object.entries(scheduleTasks)) {
            let taskStatusTemp = "0";
            if(document.getElementById("checkbox_"+value.id).checked) taskStatusTemp = "1";
            let dataObjectAttributes = {};
            dataObjectAttributes[getAttributeIdByName(attributes, "tallyos_schedules_tasks_status")] = taskStatusTemp;
            let updateTask = await upsertAsset("EDIT", value.id, dataObjectAttributes)
            if (updateTask.success === false) overallTransaction = false;
        }
        if(overallTransaction){
            navigate('/logistics/Qc/QcSchedules');
        }else{
            setAlertState({title: "Erreur", text: "Un problème est survenu. Merci de réessayer", status: true, type: "danger"});
        }
    }

    useEffect(() => {
        const triggerTokenRenewal = async() =>{
            setLoading(true);
            await renewToken();
            let attributeTemp = await getAttributeDetails();
            setAttributes(attributeTemp.response);
            setEditable(location.state.editable);

            let scheduleDetails = await getAsset('ASSET_ID', location.state.scheduleId, true, false);
            if(scheduleDetails.success && scheduleDetails.response !== undefined){
                let taskDetail = await formatTaskData(scheduleDetails.response.children, attributeTemp.response);
                setScheduleTasks(taskDetail);
                computeDailyProgresssion(taskDetail);
            }
            
            setLoading(false);
        }
        triggerTokenRenewal();
    }, [])
    return (
        // <NavbarGeneric navTitle={"Tâches"} leftIcon={{url: "/logistics/Qc/QcSchedules", params:{}}} rightIcon={"home"}></NavbarGeneric>
        <div>
            <NavbarGeneric navTitle={"Tâches"} leftIcon={{url:fromUrl, params:{fullList: location.state.fullList, editable: isEditable}}} rightIcon={"home"}></NavbarGeneric>
            {
                (isLoading) ? <div className="loader"></div>
                :<div>
                    <div className="Body">
                        <AlertBanner setStatus={setAlertState} content={alertState}/>
                        <div className="MenuButtonContainer">
                            Progression prestation : {scheduleCompletion}%
                        </div>
                        {(isEditable) ?
                            <div className="MenuButtonContainer">
                                <Button className="MenuButton" onClick={() => validateScheduleTasks()}>
                                    Valider les tâches
                                </Button>
                            </div>:null
                        }
                        <div className="MenuButtonContainer">
                            <ListGroupTasksCompletion
                                isEditable={isEditable}
                                scheduleId={location.state.scheduleId}
                                listToDisplay={scheduleTasks}
                                updateStat={updateStat}
                                fromUrl={location.state.fromUrl}
                                fullList={location.state.fullList}
                            ></ListGroupTasksCompletion>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}