import axios from 'axios';
import Alert from 'react-bootstrap/Alert';

export function booleanToBit(boolean) {
    let output = 0;
    if(boolean) output = 1;
    return output;
}

export async function isUserValid(){
    const headers = {
        'Content-Type': 'text/plain'
    };
    let tallyosSessionTemp = await localStorage.getItem('tallyosSessionToken');
    let tallyosUserTemp = await localStorage.getItem('tallyosUsername');

    let formData = new FormData();
        formData.append('LICENCE', process.env.REACT_APP_LICENCE_LOGISTICS);
        formData.append('LOGIN', tallyosUserTemp);
        formData.append('TOKEN', tallyosSessionTemp);
        formData.append('USER_INFO', 'userInfo');
    let apiResponse = await axios.post(
        process.env.REACT_APP_BASE_URL+'/auth/remember',
        formData,
        {headers}
        )
        .then(responseJSON => {
            console.log(responseJSON)
            if(responseJSON.data.status === true){
                localStorage.setItem('tallyosSessionToken', responseJSON.data.sessionToken);
                localStorage.setItem('tallyosToken', responseJSON.data.token);
                return 'connected';
            }else{
                return false;
            }
        })
        .catch(error => {
            return false;
        }
    )
    return apiResponse;
}

export async function changePasswordRequest(apiBody) {
    const headers = {
        'Content-Type': 'text/plain'
    };
    let apiResponse = await axios.post(
        process.env.REACT_APP_BASE_URL+'/password/change',
        apiBody,
        { headers }
    )
        .then(responseJSON => {
            console.log(responseJSON.data)
            //TODO: finish here
            if (responseJSON.data.status == true) {
                
                //Access granted, store token, store username and check if remember me was checked
                let responseData = responseJSON.data;
                localStorage.setItem('tallyosToken', responseData.token);
                localStorage.setItem('tallyosUsername', apiBody.get('LOGIN'));
                if (responseData.sessionToken) {// Remember me clicked, save session token
                    localStorage.setItem('tallyosSessionToken', responseData.sessionToken);
                }
            } else {
                console.log("Access refused ========>");
                alert("Wrong credentials"); //TODO: what if problem?
            }
            return { success: true, response: responseJSON };
        })
        .catch(error => {
            console.log("Error ========>", error);
            alert("Connection problem");
            return { success: false, response: error };
        }
        )
    return apiResponse;
}
/*
export function generateAlert(){
    return (
        <Alert variant="danger" className="alert" id='alertNotification'>
            Wrong credentials
        </Alert>
    )
}
*/