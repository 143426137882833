import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Redirect from './../Basic/Redirect.js';
import ForgotPassword from './../Basic/ForgotPassword.js';
import './Login.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import logo from '../../assets/tallyos_white.png';
import { booleanToBit } from './utilsLogin';
import {AlertBanner} from '../Widgets/Alerts';
import { useTranslation } from 'react-i18next';
import { useNavigate, Link} from "react-router-dom";
import {Fade, Collapse, Card, Alert} from "react-bootstrap";
import {timeout} from "workbox-core/_private";
async function loginUser(apiBody, setIsConnected, alertState, setAlertState, t, setWorkflow) {
    const headers = {
        'Content-Type': 'text/plain'
    };
    let apiResponse = await axios.post(
        process.env.REACT_APP_BASE_URL+'/auth/login',
        apiBody,
        { headers }
    )
        .then(responseJSON => {
            if (responseJSON.data.status === true) {
                console.log(responseJSON.data)
                //Access granted, store token, store username and check if remember me was checked
                let responseData = responseJSON.data;
                localStorage.setItem('tallyosToken', responseData.token);
                localStorage.setItem('tallyosUsername', apiBody.get('LOGIN'));
                localStorage.setItem('tallyosUserId', responseData.user_id);
                if (responseData.sessionToken) {// Remember me clicked, save session token
                    localStorage.setItem('tallyosSessionToken', responseData.sessionToken);
                }
                //Authorize login
                let workflow = responseData.companies[Object.keys(responseData.companies)[0]].workflow;
                console.log("Loaded from DB", workflow)
                //localStorage.setItem('tallyosConfiguration', workflow);
                setWorkflow(workflow);
                setIsConnected('connected');
                //setIsConnected({status: 'connected', workflow: workflow});
            } else {
                //setAlertState({title: t("basic.error"), text: t("basic.wrong_credentials"), status: true, type: "danger"});
                //alert("Wrong credentials"); //TODO: what if problem?
            }
            return { success: true, response: responseJSON, alertContent: {title: t("basic.error"), text: t("basic.wrong_credentials"), status: true, type: "danger"}};
        })
        .catch(error => {
            //setAlertState({title: t("basic.error"), text: t("basic.connection_error"), status: true, type: "danger"});
            return { success: false, response: error, alertContent: {title: t("basic.error"), text: t("basic.connection_error"), status: true, type: "danger"}};
        }
        )
    return apiResponse;
}

async function ForgotPwdRedirect(){
    console.log("Pop")
    
}

export default function Login({ setIsConnected, setWorkflow }) {
    
    const [alertState, setAlertState] = useState({title: "", text: "", status: false});

    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [rememberMe, setRememberMe] = useState(true);
    const [isLoading, setLoading] = useState(true);
    const [clear, setClearTimeout] = useState(false);
    const { t } = useTranslation();

    let timeoutId = null;

    useEffect(() => {
        setLoading(false);

        if (clear === true) {
            clearTimeout(timeoutId);
            setClearTimeout(false);
        }
    }, [])

    const handleSubmit = async e => {
        setLoading(true);
        e.preventDefault();
        let formData = new FormData();
        formData.append('REMEMBER', booleanToBit(true));
        formData.append('USER_INFO', 'userInfo');
        formData.append('LOGIN', username);
        formData.append('PASSWORD', password);
        const loginResponse = await loginUser(formData, setIsConnected, alertState, setAlertState, t, setWorkflow);
        setLoading(false);

        if (loginResponse.alertContent.status === true) {
            setAlertState(loginResponse.alertContent);
            timeoutId = setTimeout(() => {
                setAlertState({
                    title: loginResponse.alertContent.title,
                    type:loginResponse.alertContent.type,
                    text: loginResponse.alertContent.text,
                    status: false
                });
                setClearTimeout(true);
            }, 3000);
        }
    }

    return (
        <div className='LoginForm'>
            <div style={{ textAlign: 'center', paddingTop: '90px', paddingBottom: '75px' }}>
                <img src={logo} className="img-fluid logo" alt="Tallyos"></img>
            </div>
            {(isLoading) ? <div className="loading"><div className="loader"></div></div> : null}

            <AlertBanner setStatus={setAlertState} content={alertState}/>

            <Form onSubmit={handleSubmit}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>{t("basic.email_address")}</Form.Label>
                    <Form.Control type="email" placeholder={t("basic.email_address")} onChange={e => setUsername(e.target.value)} />
                    <Form.Text className="text-muted">
                    </Form.Text>
                </Form.Group>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>{t("basic.password")}</Form.Label>
                    <Form.Control type="password" placeholder={t("basic.password")} onChange={e => setPassword(e.target.value)} />
                </Form.Group>
                {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                    <Form.Check type="checkbox" label={t("basic.remember_me")} onChange={e => setRememberMe(e.target.checked)} />
                </Form.Group> */}
                <div style={{ textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }}>
                    <Button type="submit" style={{ backgroundColor: 'rgba(241, 136, 30)', minWidth: '200px' }}>
                        {t("basic.login")}
                    </Button>
                </div>
                <Form.Group className="mb-3" controlId="formBasicForgotPwd">
                <Form.Label onClick={e => ForgotPwdRedirect()}>{t("basic.forgot_password")}</Form.Label>
                </Form.Group>
            </Form>
        </div>

    )
}

Login.propTypes = {
    setIsConnected: PropTypes.func.isRequired
}