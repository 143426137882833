import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import './Login.css';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import './../../App.css';
import Settings from './../Basic/Settings.js'
import Password from './../Basic/Password.js'
import ForgotPassword from './../Basic/ForgotPassword.js'
import 'bootstrap/dist/css/bootstrap.min.css';
import TakePicture from './../Widgets/TakePicture';
import QcHome from './../Logistics/Qc/QcHome';
import QcMultiSchedule from './../Logistics/Qc/QcMultiSchedule'
import QcSchedules from './../Logistics/Qc/QcSchedules';
import QcTasks from './../Logistics/Qc/QcTasks';
import QcTaskDetail from './../Logistics/Qc/QcTaskDetail';
import './../../assets/i18nextConf.js'

export default function Redirect({ setIsConnected, workflow}) {
    const [isLoading, setLoading] = useState(true);
    useEffect(() => {
        setLoading(false);
    }, [])

    if (isLoading) {
        return  <div>
                  <div className="loader"></div>
                </div>;
      }else{
        if(workflow === "hygipronet"){
          return (
            <div className="wrapper">
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<QcHome/>} />
                  <Route path="/logistics/Qc/QcHome" element={<QcHome/>} />
                  <Route path="/logistics/Qc/QcSchedules" element={<QcSchedules/>} />
                  <Route path="/logistics/Qc/QcMultiSchedule" element={<QcMultiSchedule/>} />
                  <Route path="/logistics/Qc/QcTasks" element={<QcTasks/>} />
                  <Route path="/logistics/Qc/QcTaskDetail" element={<QcTaskDetail/>} />
                  <Route path="/settings" element={<Settings/>} />
                  <Route path="/password" element={<Password/>} />
                  <Route path="/forgotPassword" element={<ForgotPassword/>} />
                  <Route path="/takePicture" element={<TakePicture/>} />
                </Routes>
              </BrowserRouter>
            </div>
          );
        } else {
          return (
            <div className="wrapper">
              <BrowserRouter>
                <Routes>
                  <Route path="/" element={<Settings/>} />
                  <Route path="/logistics/qc/qcHome" element={<QcHome/>} />
                  <Route path="/settings" element={<Settings/>} />
                  <Route path="/password" element={<Password/>} />
                  <Route path="/takePicture" element={<TakePicture/>} />
                </Routes>
              </BrowserRouter>
            </div>
          );
        }
      }

}

Redirect.propTypes = {
    setIsConnected: PropTypes.func.isRequired
}