import React, {useState} from "react";
import Camera from 'react-html5-camera-photo';
import 'react-html5-camera-photo/build/css/index.css';

export default function TakePicture(){
    const [isPicturing, setPicturing] = useState(false);
    const [dataUri, setDataUri] = useState(null);

    function handleTakePhoto (dataUri) {
      setDataUri(dataUri);
      console.log(dataUri)
      }

    const getPicture = () => {
        setPicturing(true);
        
    }

        return (
          <div>
            {
              (dataUri)
                ? <img className="card-img-top" src={dataUri} alt="Card image cap" />
                : <Camera
                onTakePhoto = { (dataUri) => { handleTakePhoto(dataUri); } }
                imageCompression = {0.1}
                idealResolution = {{width: 640, height: 480}}
                isFullscreen={true}
              />
            }
          </div>
        );
    

}
