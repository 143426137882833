import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import './Login.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import logo from '../../assets/tallyos_white.png';
import { booleanToBit } from './utilsLogin';
import {AlertBanner} from '../Widgets/Alerts';
import { useTranslation } from 'react-i18next';

export default function ForgotPassword() {
    const [alertState, setAlertState] = useState({title: "", text: "", status: false});
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [rememberMe, setRememberMe] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const { t } = useTranslation();
    useEffect(() => {
        setLoading(false);
    }, [])
    const handleSubmit = async e => {
        setLoading(true);
        e.preventDefault();
        let formData = new FormData();
        //formData.append('LOGIN', 'daniel@tallyos.com');
        //formData.append('PASSWORD', 'Tallyos123');
        formData.append('LOGIN', username);
        formData.append('PASSWORD', password);
        formData.append('REMEMBER', booleanToBit(rememberMe));
        formData.append('USER_INFO', 'userInfo');
        setLoading(false);
    }

    if (isLoading) {
        return <div>
            <div className="loader"></div>
        </div>;
    } else {
        return (
            <div className='LoginForm'>
                <AlertBanner setStatus={setAlertState} content={alertState}/>
                <div style={{ textAlign: 'center', paddingTop: '90px', paddingBottom: '75px' }}>
                    <img src={logo} className="img-fluid" style={{ width: '80%' }} alt="Tallyos"></img>
                </div>
                <Form onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label>{t("basic.email_address")}</Form.Label>
                        <Form.Control type="email" placeholder={t("basic.email_address")} onChange={e => setUsername(e.target.value)} />
                        <Form.Text className="text-muted">
                        </Form.Text>
                    </Form.Group>
                    
                    <div style={{ textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }}>
                        <Button type="submit" style={{ backgroundColor: 'rgba(241, 136, 30)', minWidth: '200px' }}>
                            {t("basic.login")}
                        </Button>
                    </div>
                </Form>                
            </div>
            

        )
    }

}
