import React from "react";
import ListGroup from 'react-bootstrap/ListGroup';
import { FaCircle, FaSearch, FaEdit} from "react-icons/fa";
import { useNavigate, useLocation} from "react-router-dom";
import {combineScheduleListDescription, computeSiteCompletion, getScheduleColorFromCompletion} from "../Logistics/Qc/UtilsQc.js";


export function ListGroupSchedulesCompletion({listToDisplay}) {
  let navigate = useNavigate();
  let location = useLocation();

  let fromUrl = "/logistics/Qc/QcSchedules";
  let editable = false;
  let fullList = [];

  if (typeof(location.state) !== 'undefined' && location.state !== null) {
    if (typeof(location.state.editable) !== 'undefined')
      editable = location.state.editable;

    if (typeof(location.state.fromUrl) !== 'undefined')
      fromUrl = location.state.fromUrl;

    if (typeof(location.state.fullList) !== 'undefined')
      fullList = location.state.fullList;
  }

  var item = <ListGroup>
    {listToDisplay.map((value, idx) =>{
      return(
        <ListGroup.Item className="list-group-item row d-flex" style={{margin: '5px'}} key={idx} onClick={() => redirectToScheduleDetails(value.schedule_id, editable, navigate, value, fromUrl, fullList)} >
          <div className="col-9 d-flex align-items-center justify-content-start" style={{paddingLeft:'0'}} >
						<div className={getScheduleColorFromCompletion(computeSiteCompletion(value.schedule_completion))} style={{fontWeight: 'bold', fontSize: 30, textAlign: 'center', paddingBottom:'0.3em'}} ><FaCircle/></div>
						<div style={{marginLeft : '1em', textAlign: 'left'}}>
							<div style={{fontWeight: 'bold'}}>{value.schedule_site}</div>
							<div>{combineScheduleListDescription(value.schedule_description)}</div>
						</div>
					</div>
          <div className="col-3 d-flex align-items-center justify-content-end" >
						<div>{computeSiteCompletion(value.schedule_completion)}% </div>
						<div style={{marginLeft : '1em'}}><FaSearch/> </div>
					</div>
        </ListGroup.Item>)
    })}
  </ListGroup>;
  return item;
}

export function redirectToScheduleDetails(scheduleIds, editable, navigate, value, fromUrl, fullList){
  if(scheduleIds.length === 1){
    navigate('/logistics/Qc/QcTasks', {state:{scheduleId: scheduleIds[0], editable: editable, fullList: fullList, fromUrl:fromUrl}});
  }else{
    navigate('/logistics/Qc/QcMultiSchedule', {state:{scheduleId: scheduleIds, editable: editable, fullList: value, fromUrl:fromUrl}});
  }
}

export function ListGroupTasksCompletion({listToDisplay, isEditable, updateStat, scheduleId , fromUrl, fullList}) {
  let navigate = useNavigate();
  if(listToDisplay){
    var item = <ListGroup>
    {listToDisplay.map((value, idx) =>{
      return(
        <ListGroup.Item className="list-group-item row d-flex" style={{margin: '5px'}} key={idx}  >
          <div className="col-10 d-flex align-items-center justify-content-start">
            <div className="form-check" style={{paddingLeft: '0', marginBottom : '0'}}>
              <input type="checkbox" id={"checkbox_"+value.id} name={idx} disabled={!isEditable} defaultChecked={value.status} onChange={()=> {
                updateStat();
              }}/>
            </div>
            <div style={{marginLeft: '1em', textAlign: 'left'}}>{value.name}</div>
          </div>
          <div className="col-2 d-flex align-items-center justify-content-end" onClick={() =>
              navigate('/logistics/Qc/QcTaskDetail', {state:{taskId: value.id, editable: isEditable, scheduleId: scheduleId, fullList: fullList, fromUrl: fromUrl}})
          } >
            <div>
              { (isEditable) ? <FaEdit/> : <FaSearch/> }
            </div>
          </div>
        </ListGroup.Item>)
    })}
  </ListGroup>;
  return item;
  }
}