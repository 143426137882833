import React, {useState, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import NavbarGeneric from "./NavbarGeneric.js";
import logo from '../../assets/tallyos.png';
import { useNavigate} from "react-router-dom";
import { renewToken } from "../Utils/utilsConnectivity.js";
export default function Settings(){
    const [isLoading, setLoading] = useState(true);
    let navigate = useNavigate();
    const handleDeco = async ()  => {
        localStorage.removeItem('tallyosSessionToken');
        localStorage.removeItem('tallyosToken');
        localStorage.removeItem('tallyosConfiguration');
        navigate('/');
        window.location.reload(false);
    }
    useEffect(() => {
        console.log(navigate.referrer);
        const triggerTokenRenewal = async() =>{
            setLoading(true);
            await renewToken();
            setLoading(false);
        }
        triggerTokenRenewal();
    }, [])
    if (isLoading) {
        return <div>
            <div className="loader"></div>
        </div>;
    } else {
        return(
            <div>
                <NavbarGeneric navTitle={"Réglages"} leftIcon={{url: "/", params:{}}}></NavbarGeneric>
                <div className="Body">
                    <div className="MenuButtonContainer">
                        <Button type="submit" className="MenuButton" onClick={() => navigate('/password')}>
                            Mot de passe
                        </Button>
                    </div>
                    <div className="MenuButtonContainer">
                        <Button type="submit" className="MenuButton" onClick={() => handleDeco()}>
                            Déconnexion
                        </Button>
                    </div>
                    <div className="MenuButtonContainer">
                        { process.env.REACT_APP_VERSION }
                    </div>
                </div>
            </div>
        )
    }
}