import React, {useState, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import NavbarGeneric from "../../Basic/NavbarGeneric.js";
import './Qc.css';
import logo from '../../../assets/tallyos.png';
import { useNavigate} from "react-router-dom";
import { renewToken } from "../../Utils/utilsConnectivity.js";
export default function QcHome(){
    const [isLoading, setLoading] = useState(true);
    let navigate = useNavigate();
    useEffect(() => {
        const triggerTokenRenewal = async() =>{
            setLoading(true);
            await renewToken();
            setLoading(false);
        }
        triggerTokenRenewal();
    }, [])

    if (isLoading) {
        return <div>
            <NavbarGeneric navTitle={"Accueil"} leftIcon={{url: "/logistics/Qc/QcHome", params:{}}} rightIcon={"settings"}></NavbarGeneric>
            <div className="loader"></div>
        </div>;
    } else {
        return(
            <div>
                <NavbarGeneric navTitle={"Accueil"} leftIcon={{url: "/logistics/Qc/QcHome", params:{}}} rightIcon={"settings"}></NavbarGeneric>
                <div className="Body">
                    <div className="MenuButtonContainer" style={{textAlign: 'center', paddingTop: '90px', paddingBottom: '75px'}}>
                        <img src={logo} className="img-fluid logo" alt="Tallyos"></img>
                    </div>
                    <div className="MenuButtonContainer">
                        <Button className="MenuButton" onClick={() => navigate('/logistics/Qc/QcSchedules')}>
                            Prestations
                        </Button>
                    </div>
                </div>
                
            </div>
        )
    }
}