import axios from 'axios';

export async function renewToken(){
    let lastRefresh = await parseInt(localStorage.getItem('tallyosTokenRefreshDate'));
    if(Date.now()-lastRefresh > 86400000){
        console.log("Renew")
        const headers = {
            'Content-Type': 'text/plain'
        };
        let tallyosSessionTemp = await localStorage.getItem('tallyosSessionToken');
        let tallyosUserTemp = await localStorage.getItem('tallyosUsername');
        let formData = new FormData();
            formData.append('LICENCE', process.env.REACT_APP_LICENCE_LOGISTICS);
            formData.append('LOGIN', tallyosUserTemp);
            formData.append('TOKEN', tallyosSessionTemp);
            formData.append('USER_INFO', 'userInfo');
        let apiResponse = await axios.post(
            process.env.REACT_APP_BASE_URL+'/auth/remember',
            formData,
            {headers}
            )
            .then(responseJSON => {
                if(responseJSON.data.status === true){
                    localStorage.setItem('tallyosSessionToken', responseJSON.data.sessionToken);
                    localStorage.setItem('tallyosToken', responseJSON.data.token);
                    localStorage.setItem('tallyosTokenRefreshDate', Date.now());
                    console.log('Token renewed', Date.now());
                }
            })
            .catch(error => {
                return false;
            }
        )
        return apiResponse;
    }   
}