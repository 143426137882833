import React, {useState, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import NavbarGeneric from "./NavbarGeneric.js";
import Form from 'react-bootstrap/Form';
import { useNavigate} from "react-router-dom";
import { renewToken } from "../Utils/utilsConnectivity.js";
import {changePasswordRequest} from "./utilsLogin.js"
import {AlertBanner} from '../Widgets/Alerts';
import { useTranslation } from 'react-i18next';

export default function Password(){
    const [isLoading, setLoading] = useState(true);
    const [alertState, setAlertState] = useState({title: "", text: "", status: false});
    const [passwordOld, setPasswordOld] = useState("");
    const [passwordNew1, setPasswordNew1] = useState("");
    const [passwordNew2, setPasswordNew2] = useState("");
    const { t } = useTranslation();
    let navigate = useNavigate();
    useEffect(() => {
        const triggerTokenRenewal = async() =>{
            setLoading(true);
            await renewToken();
            setLoading(false);
        }
        triggerTokenRenewal();
    }, [])
    const handleSubmit = async e => {
        setLoading(true);
        e.preventDefault();
        console.log(passwordNew1, passwordNew2, passwordOld)
        if(passwordNew1 === passwordNew2){
            if(passwordNew1 !== ""){
                let tallyosTokenTemp = await localStorage.getItem('tallyosToken');
                let tallyosUserTemp = await localStorage.getItem('tallyosUsername');

                let formData = new FormData();
                formData.append('LICENCE', process.env.REACT_APP_LICENCE_LOGISTICS);
                formData.append('PASSWORD', passwordOld);
                formData.append('NEW_PASSWORD', passwordNew1);
                formData.append('TOKEN', tallyosTokenTemp);
                formData.append('LOGIN', tallyosUserTemp);
                const changePasswordResponse = await changePasswordRequest(formData);

                if (changePasswordResponse.status) {
                    setAlertState({title: "Success", text: "Mot de passe mis à jour", status: true, type: "success"});
                } else {
                    setAlertState({title: t("basic.error"), text: "Le nouveau mot de passe est invalide", status: true, type: "danger"});
                }
            }else{
                setAlertState({title: t("basic.error"), text: "Le nouveau mot de passe est invalide", status: true, type: "danger"});
            }
        }else{
            setAlertState({title: t("basic.error"), text: "Les mots de passe ne correspondent pas", status: true, type: "danger"});
        }
        setLoading(false);
    }

    return (
        <div>
            <NavbarGeneric navTitle={"Mot de passe"} leftIcon={{url:"/settings", params:{}}} rightIcon={"settings"}></NavbarGeneric>
            <div>
                <div className="Body">
                <AlertBanner setStatus={setAlertState} content={alertState}/>
                {
                    (isLoading) ?
                        <div className="loader"></div>
                    :
                        <Form onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formBasicPassword1">
                                <Form.Label>Mot de passe actuel</Form.Label>
                                <Form.Control type="password" placeholder="Mot de passe actuel" onChange={e => setPasswordOld(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword2">
                                <Form.Label>Nouveau mot de passe</Form.Label>
                                <Form.Control type="password" placeholder="Nouveau mot de passe" onChange={e => setPasswordNew1(e.target.value)} />
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formBasicPassword3">
                                <Form.Label>Nouveau mot de passe</Form.Label>
                                <Form.Control type="password" placeholder="Nouveau mot de passe" onChange={e => setPasswordNew2(e.target.value)} />
                            </Form.Group>
                            <div style={{ textAlign: 'center', paddingTop: '10px', paddingBottom: '10px' }}>
                                <Button type="submit" style={{ backgroundColor: 'rgba(241, 136, 30)', minWidth: '200px' }}>
                                    Login
                                </Button>
                            </div>
                        </Form>
                }
                </div>
            </div>
        </div>
    )
}